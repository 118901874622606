var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('notifications',{attrs:{"group":"event"}}),_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 活動列表 ")]},proxy:true}])},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"tag":"button","to":"/admin/createEvent"}},[_c('b-icon-plus'),_vm._v(" 新增活動 ")],1)],1)],1),_c('b-table',{attrs:{"fields":_vm.fields,"head-variant":"light","hover":"","sort-icon-left":"","items":_vm.eventList},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('b-alert',{attrs:{"show":"","variant":"secondary"}},[_vm._v("請新增活動")])]},proxy:true},{key:"cell(cover)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"eventImage"},[(item.cover)?_c('img',{attrs:{"src":item.cover,"alt":"aira 城智科技活動照片"}}):_vm._e()])]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [(item.category)?_c('div',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(item.category.zh)+" ")]):_vm._e()]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [(item.zh &&  item.zh.title)?_c('div',[_vm._v(" 中文: "+_vm._s(item.zh.title)+" ")]):_vm._e(),(item.en &&  item.en.title)?_c('div',[_vm._v(" 英文: "+_vm._s(item.en.title)+" ")]):_vm._e()]}},{key:"cell(createDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"cell(edit)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"text-right nowrap"},[_c('b-icon',{staticClass:"event-icon",attrs:{"id":("edit-event" + index),"font-scale":"1.2","icon":"pencil"},on:{"click":function($event){return _vm.handlePutEvent(item)}}}),_c('b-tooltip',{attrs:{"target":("edit-event" + index),"placement":"left"}},[_vm._v(" 編輯活動 ")]),_c('b-icon',{staticClass:"event-icon",attrs:{"id":("delete-event" + index),"font-scale":"1.2","icon":"trash"},on:{"click":function($event){return _vm.handleDeleteEvent(item._id)}}}),_c('b-tooltip',{attrs:{"target":("delete-event" + index),"placement":"left"}},[_vm._v(" 刪除活動 ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }