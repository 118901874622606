<template>
    <b-container fluid>
        <notifications group="event" />

        <b-card>
            <template #header>
                活動列表
            </template>

            <b-row class="mb-2">
                <b-col>
                    <router-link tag="button" to="/admin/createEvent" class="btn btn-primary">
                        <b-icon-plus></b-icon-plus>
                        新增活動
                    </router-link>
                </b-col>
                <!-- need to add filter -->
                <!-- <b-col class="text-xs-right"></b-col> -->
            </b-row>

            <b-table
                :fields="fields"
                head-variant="light"
                hover
                sort-icon-left
                :items="eventList">

                <template v-slot:empty>
                    <b-alert show variant="secondary">請新增活動</b-alert>
                </template>

                <template v-slot:cell(cover)="{ item }">
                    <div class="eventImage">
                        <img v-if="item.cover" :src="item.cover" alt="aira 城智科技活動照片">
                    </div>
                </template>

                <template v-slot:cell(category)="{ item }">
                    <div class="nowrap" v-if="item.category">
                        {{ item.category.zh }}
                    </div>
                </template>

                <template v-slot:cell(title)="{ item }">
                    <div  v-if="item.zh &&  item.zh.title" > 中文: {{ item.zh.title }} </div>
                    <div  v-if="item.en &&  item.en.title"> 英文: {{ item.en.title }} </div>
                </template>

                <template v-slot:cell(createDate)="{ item }">
                    {{ formatDate(item.created_at) }}
                </template>

                <template v-slot:cell(edit)="{ item, index }">
                    <div class="text-right nowrap">
                        <b-icon 
                            @click="handlePutEvent(item)"
                            :id="`edit-event${index}`"
                            font-scale="1.2"
                            class="event-icon"
                            icon="pencil">
                        </b-icon>
                        <b-tooltip :target="`edit-event${index}`" placement="left">
                            編輯活動
                        </b-tooltip>
    
                        <b-icon
                            @click="handleDeleteEvent(item._id)"
                            :id="`delete-event${index}`"
                            font-scale="1.2"
                            class="event-icon"
                            icon="trash"
                            >
                        </b-icon>
                        <b-tooltip :target="`delete-event${index}`" placement="left">
                            刪除活動
                        </b-tooltip>
                    </div>
                </template>
            </b-table>

        </b-card>
    </b-container>
</template>

<script>
import { getEvents, deleteEvent } from '@/apis/apiEvents';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            fields: [
            {
                key: 'cover',
                label: '活動封面圖',
                class: 'nowrap',
            },
            {
                key: 'category',
                label: '分類',
                sortable: true,
                class: 'nowrap',
            },
            {
                key: 'title',
                label: '標題',
                class: 'nowrap',
            },
            {
                key: 'activityDate',
                label: '活動日期',
                sortable: true,
                class: 'nowrap',
            },
            {
                key: 'createDate',
                label: '創建日期',
                sortable: true,
            },
            {
                key: 'edit',
                label: '',
            }
            ],

            eventList: [],
        }
    },

    created() {
        this.initialView();
    },

    methods: {
        async initialView() {
            const eventData = await getEvents({
                currentPage: 1,
                perPageItems: 100,
                category: '',
            });

            this.eventList = eventData.eventList;
        },

        handlePutEvent(item) {
            this.$router.push({
                path: '/admin/putEvent',
                query: {
                    eventTitle: item['zh']['title']
                },
            })
        },

        async handleDeleteEvent(id) {
            const result = await deleteEvent(id);

            if (result.status === 200) {
                this.$notify({
                    group: 'event',
                    type: 'success',
                    title: '成功刪除',
                    position: 'top center',
                });

                const eventData = await getEvents({
                    currentPage: 1,
                    perPageItems: 100,
                    category: '',
                });

                this.eventList = eventData.eventList;
                return;
            } 

            this.$notify({
                group: 'event',
                type: 'warn',
                title: `${result.message}`,
                position: 'top center',
            });
        },
        
        formatDate(date) {
            return dayjs(date).format('YYYY/MM/DD');
        }
    }
}
</script>

<style lang="scss" scoped>
.event-icon{
    color: #616161;
    cursor: pointer;
    margin: 0 6px;
}

.eventImage{
    img {
        object-fit: cover;
        width: 100%;
        max-width: 90px;
    }
}

.nowrap {
    white-space: nowrap;
}
</style>